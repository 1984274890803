import { createContext, useState } from 'react';

interface NavContextValue {
  items: { title: string; slug: string }[];
  active: string;

  setActive: React.Dispatch<React.SetStateAction<string>>;
  setItems: React.Dispatch<
    React.SetStateAction<{ title: string; slug: string }[]>
  >;
}

const initialContextState = {
  items: [],
  active: '',

  setActive: () => {},
  setItems: () => {}
};

const NavContext = createContext<NavContextValue>(initialContextState);

function NavProvider({ children }: React.PropsWithChildren) {
  const [active, setActive] = useState<NavContextValue['active']>(
    initialContextState.active
  );

  const [items, setItems] = useState<NavContextValue['items']>([]);

  return (
    <NavContext.Provider
      value={{
        items,
        active,
        setActive,
        setItems
      }}
    >
      {children}
    </NavContext.Provider>
  );
}

export { NavProvider, NavContext };
